<template>
  <!-- <div> -->
    <b-container fluid="xl" style="max-width: 1400px;height:100%">
      <b-row v-if="loading" class="justify-content-center my-3"
        ><b-spinner variant="secondary"
      /></b-row>
      <b-row class="p-auto">
        <b-col
          v-for="cam in trafficCams"
          :key="cam.name"
          sm="4"
          lg="3"
          class="p-0"
        >
          <b-card
            :id="'img-' + cam.camUrl"
            :img-src="trafficCamsBaseUrl + cam.camUrl + '?' + urlSalt"
            class="m-2"
            no-body
          >
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  <!-- </div> -->
</template>

<script>
const trafficCamsBaseUrl = "https://www.vmz-niedersachsen.de/webcam/image/";
const trafficCams = [
  {
    name: "A2 Bad Nenndorf",
    camUrl: "a2-bad-nenndorf-15",
  },
  {
    name: "AD Hannover-West",
    camUrl: "a2-stelinger-strasse-25",
  },
  {
    name: "A2 H-Bothfeld",
    camUrl: "a2-bothfeld-19",
  },
  {
    name: "A2 H-Lahe",
    camUrl: "a2-lahe-24",
  },
  {
    name: "A2 Herrenhausen",
    camUrl: "a2-herrenhausen-26",
  },
  {
    name: "A2/A7 AK Ost",
    camUrl: "a2a7ak-hannover-ost-21",
  },
  {
    name: "A7 Mellendorf",
    camUrl: "a7-mellendorf-23",
  },
  {
    name: "A7 Großburgwedel",
    camUrl: "a7-grossburgwedel-22",
  },
  {
    name: "A7 AD Süd",
    camUrl: "a7-ad-hannover-sued-16",
  },
  {
    name: "A7 H-Anderten",
    camUrl: "a7-as-anderten-20",
  },
  {
    name: "A7 Laatzen",
    camUrl: "a7-laatzen-17",
  },
  {
    name: "A7 H-Wülferode",
    camUrl: "a7-rst-wuelferode-18",
  },
];

export default {
  data() {
    return {
      imgsrc: "",
      trafficCams,
      trafficCamsBaseUrl,
      polling: null,
      urlSalt: 0,
      loading: false,
    };
  },
  computed: {},
  mounted() {},
  beforeDestroy() {
    this.autoUpdate(false);
  },
  created() {
    this.autoUpdate(true);
    setTimeout(()=> {this.loading=false},400)
  },
  methods: {
    autoUpdate(enable) {
      if (enable) {
        this.polling = setInterval(() => {
          this.urlSalt = Math.trunc(Math.random() * 100000);
          console.log("update " + this.urlSalt);
        }, 1000 * 15);
      } else {
        this.polling = null;
      }
    },
  },
};
</script>

<style>
</style>