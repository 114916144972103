<template>
  <div>
    <b-container fluid="xl" style="max-width: 1400px; height: 100%">
      <b-row v-if="loading" class="justify-content-center my-3"
        ><b-spinner variant="secondary"
      /></b-row>

      <b-row class="my-2">
        <b-col fluid="lg">
          <b-form-tags
            v-show="!loading"
            input-id="tags-separators"
            v-model="filterValues"
            separator=",;"
            placeholder="Filter hinzufügen"
            remove-on-delete
          ></b-form-tags>
        </b-col>
      </b-row>

      <!-- <b-row v-for="e in filteredContent" :key="e.id" class="my-1"> -->
      <!-- <b-col lg="12" class="m-auto"> -->
      <b-card-group columns>
        <b-card
          v-for="mc in mowasContentFiltered"
          :key="mc.identifier"
          no-body
          border-variant="light"
        >
          <b-card-body>
            <time class="from-bbk">{{ mc.sent | formatDate }}</time>
            <h2 class="from-bbk">{{ mc.info.areaDesc }}</h2>
            <b-card-text>
              <div v-html="mc.info.description"></div>
            </b-card-text>
          </b-card-body>
          <b-card-footer>
            <h3>
              <b-badge variant="danger" class="mr-1 px-3"
                ><b-icon icon="exclamation-triangle"
              /> MoWaS</b-badge>
            </h3>
          </b-card-footer>
        </b-card>

        <b-card v-for="e in filteredContent" :key="e.id" no-body bg-variant="">
          <b-card-body>
            <b-card-text>
              <div v-html="e.properties.description"></div>
            </b-card-text>
          </b-card-body>
          <b-card-footer>
            <h3>
              <b-badge
                variant="primary"
                v-for="p in e.properties.tags.autobahn"
                :key="p"
                class="mr-1"
                >{{ p }}</b-badge
              >
              <b-badge
                variant="warning"
                v-for="q in e.properties.tags.bundesstr"
                :key="q"
                class="mr-1"
                >{{ q }}</b-badge
              >
              <b-badge
                variant="info"
                v-for="r in e.properties.tags.andere"
                :key="r"
                class="mr-1"
                >{{ r }}</b-badge
              >
            </h3>
          </b-card-footer>
        </b-card>
      </b-card-group>
      <!-- </b-col> -->
      <!-- </b-row> -->
    </b-container>
  </div>
</template>

<script>
import Axios from "axios";
const strftime = require("strftime").localizeByIdentifier("de_DE");
// // axios.defaults.baseURL = "http://192.168.2.5:8888/"

const baseURL = "/proxy.php?csurl="


export default {
  data() {
    return {
      content: [],
      mowasContent: [],
      loading: true,
      polling: null,
      filterValues: [],
      firstPolling: true
    };
  },

  mounted() {
    this.getData();
    this.filterValues = JSON.parse(localStorage.getItem("filterArr"));
  },

  created() {
    this.autoUpdate();
  },

  beforeDestroy() {
    this.autoUpdate(false);
  },

  methods: {
    async getData() {
      this.loading = true;
      try {
        const tr = await Axios.get(baseURL+"https://vmz-niedersachsen.de/geojson/traffic-nds.geojson");
        const kat = await Axios.get(baseURL+"https://warnung.bund.de/bbk.mowas/gefahrendurchsagen.json");

        if (this.mowasContent.length != kat.data.length) {
          this.mowasContent = kat.data;

          if (!this.firstPolling) {
            this.makeToast("bbk");
          }
        }



        const inclConstruction = true;
        var co = {
          data: {
            features: [],
          },
        };

        if (inclConstruction) {
          co = await Axios.get(baseURL+"https://vmz-niedersachsen.de/geojson/construction-work.geojson");
        }

        const d = {
          data: {
            features: tr.data.features.concat(co.data.features),
          },
        };
        console.log(d.data.features.length);
        if (this.content.length != d.data.features.length) {
          this.content = d.data.features;
          if (!this.firstPolling) {
            this.makeToast();
          }
        }
        //   document.getElementById("text").innerHTML = d.data.features[0].id
        this.loading = false;
      } catch (e) {
        console.error(e);
      }

      this.firstPolling = false;
    },
    makeToast(type = "traffic") {
      if (type === "traffic") {
        this.$bvToast.toast(`Die Verkehrsmeldungen wurden aktualisiert`, {
          title: "Neue Meldungen",
          variant: "primary",
          autoHideDelay: 1000,
          appendToast: false,
        });
      } else if (type == "bbk") {
        this.$bvToast.toast(`Die MoWaS-Warnungen wurden aktualisiert`, {
          title: "Neue Warnungen",
          variant: "warning",
          autoHideDelay: 1000,
          appendToast: false,
        });
      }
    },
    autoUpdate(enable = true) {
      if (enable) {
        this.polling = setInterval(this.getData, 60 * 1000);
      } else {
        clearInterval(this.polling);
      }
    },
  },
  filters: {
    formatDate(value) {
      let d = new Date(value);
      return strftime("%D, %H:%M", d);
    },
  },

  computed: {
    filteredContent() {
      var modifiedContent = [];
      var h = document.createElement("textarea");

      this.content.forEach((element) => {
        h.innerHTML = element.properties.description;

        if (
          !this.filterValues.length ||
          this.filterValues.some((value) => {
            return element.properties.description.includes(value);
          })
        ) {
          // console.log(element);

          var tags = {
            autobahn: element.properties.tagGroups.Autobahnen.tags,
            bundesstr: element.properties.tagGroups.Bundesstraßen.tags,
            andere: element.properties.tagGroups.Andere.tags,
          };

          // element.properties.tagGroups.autobahnen.tags.forEach(element => {
          //   tags.push(element)
          // });

          modifiedContent.push({
            id: element.id,
            properties: {
              description: element.properties.description,
              updatedAt: element.properties.updatedAt,
              roadNumber: element.properties.roadNumber,
              tags,
            },
          });
          // console.log(modifiedContent);
        }
      });
      return modifiedContent;
    },
    mowasContentFiltered() {

      var modifiedContent = [];

      this.mowasContent.forEach((element) => {
        if (
          !this.filterValues.length ||
          this.filterValues.some((value) => {
            return element.info[0].description.includes(value) ||
                element.info[0].area[0].areaDesc.includes(value)
          })
        ) {
          modifiedContent.push({
            identifier: element.identifier,
            sent: element.sent,
            info: {
              description: element.info[0].description,
              areaDesc: element.info[0].area[0].areaDesc,
            },
          });
          // console.log(modifiedContent);
        }
      });
      return modifiedContent;
    },
  },
  watch: {
    filterValues: function (newVal) {
      localStorage.setItem("filterArr", JSON.stringify(newVal));
    },
  },
};
</script>

<style>
.b-form-tag {
  padding: 2px;
  margin-right: 4px;
}
.b-form-tag-content {
  font-family: "Work Sans", Helvetica, Arial, sans-serif;

  margin: 4px;
  font-size: 1.1em;
}
</style>
