<template>
  <div id="app">
    <b-navbar v-show="true" toggleable="sm" id="nav" variant="dark" type="dark">
      <b-navbar-brand href="#">
        <img
          src="./assets/trafficico.png"
          class="d-inline-block align-top"
          alt="Logo"
          style="width: auto; height: 32px"
        />
      </b-navbar-brand>

      <b-navbar-toggle target="navbar-toggle-collapse"> </b-navbar-toggle>
      <b-collapse id="navbar-toggle-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/" :active="$route.path == '/'">Verkehr</b-nav-item>
          <b-nav-item to="/webcam" :active="$route.path == '/webcam'">Webcams</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-if="displayTime"
            ><b-badge>{{ displayTime }}</b-badge></b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view />
  </div>
</template>

<script>


export default {
  name: "App",
  data() {
    return {
      displayTime: "",
    };
  },
  mounted() {
    // setInterval(() => {
    //   const d = new Date(Date.now());
    //   this.displayTime = d.toLocaleString("de-DE");
    // }, 1000);
  },
};
</script>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,700;1,400&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap");
html, body {
  height:100%;
}
#app {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  /* color: #1d2227; */
  font-size: 18px;
  height: 100%;
}
h2.vmznds-situation--tic__direction,
h2.from-bbk {
  font-size: 1.6em;
}
h1.vmznds-situation--tic__road-number {
  color: rgb(114, 114, 114);
  font-weight: 300;
  display: none;
}
em.vmznds-state {
  border-radius: 5px;
}
em.vmznds-state--1 {
  background-color: rgb(216, 76, 57);
}
em.vmznds-state--2 {
  background-color: rgb(218, 162, 42);
}
em.vmznds-state--3 {
  background-color: rgb(53, 230, 47);
}
</style>
