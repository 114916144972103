import Vue from 'vue'
import VueRouter from 'vue-router'
import Verkehr from '@/views/Verkehr'
import Kameras from '@/views/Kameras'
Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    component: Verkehr
  },
  {
    path: '/webcam',
    component: Kameras
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
